import { useEffect, useRef } from 'react';
import type { v1 } from '@userlike/messenger';
import { logger, useConfig } from '@packages/utilities';
import { usePathname } from 'next/navigation';
import type { ChatBotConfig } from '@packages/config/src/default';
import { shouldDisplayChatBot } from './utils/shouldDisplayChatBot';

/**
 * Initializes and manages the Userlike Messenger (ChatBot) based on route changes.
 * This component handles the lifecycle of the ChatBot, ensuring it is correctly
 * mounted or unmounted as the application's route changes. It leverages the provided
 * configuration to control the visibility and behavior of the ChatBot across different
 * parts of the application.
 *
 * @param {ChatBotConfig} props The configuration for the ChatBot, including which paths
 * it should appear on and its visibility settings.
 */
const ChatBotInit = ({
  visibility,
  widgetKey,
}: Pick<ChatBotConfig, 'visibility'> & { widgetKey: string }) => {
  const apiInstanceRef = useRef<v1.Api>();
  const mounted = useRef(false);

  /**
   * Asynchronously creates and mounts the ChatBot API.
   */
  const createAndMountApi = async () => {
    const { createMessenger } = await import('@userlike/messenger');

    const result = await createMessenger({
      version: 1,
      widgetKey,
    });

    if (result.kind === 'error') {
      logger.error('Chatbot: ', result.error, ` Failed to create Messenger: ${result.error}`);
      return null;
    }

    const { api } = result.value;
    if (api === null) {
      logger.error('Chatbot: ', 'API reached end-of-life, please check documentation and upgrade.');
      return null;
    }

    const mountResult = await api.mount();
    if (mountResult.kind === 'error') {
      // we don't want to create massive logs in our SPA
      // logger.error('Chatbot: ', mountResult.error, `Failed to mount Messenger: ${mountResult.error}`);
      return null;
    }

    await api.setVisibility(visibility);

    apiInstanceRef.current = api;

    return null;
  };

  /**
   * Manages ChatBot mounting/unmounting via useEffect.
   * @see createAndMountApi
   */
  useEffect(() => {
    const mountChatBot = async () => {
      if (!apiInstanceRef.current) {
        try {
          await createAndMountApi();
        } catch (e) {
          logger.error(e, `Failed to mount ChatBot messenger: ${e}`);
        }
      }
    };

    const unmountChatBot = async () => {
      if (apiInstanceRef.current) {
        try {
          await apiInstanceRef.current.unmount();
          apiInstanceRef.current = undefined;
        } catch (e) {
          logger.error(e, `Failed to unmount ChatBot messenger: ${e}`);
        }
      }
    };

    // just to avoid errors in strict mode. does not affect react production mode
    if (!mounted.current) {
      mountChatBot();
      mounted.current = true;
    }

    /**
     * Cleanup function to unmount the ChatBot when the component is unmounted.
     */
    return () => {
      unmountChatBot();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

/**
 * A wrapper component for `ChatBotInit`. This component is solely focused on side effects,
 * specifically for mounting or unmounting the chatbot, and does not render any visual UI elements.
 * As such, it consistently returns `null` after performing its operations.
 *
 * @see https://github.com/userlike/messenger
 * @see https://docs.userlike.com/setup/integration/api
 * @returns {null}
 */
export const ChatBot = () => {
  const { chatBot: chatBotConfig } = useConfig();
  const pathname = usePathname();
  const { key, includePaths, excludePaths } = chatBotConfig;
  const showChatBot = shouldDisplayChatBot({ path: pathname || '', includePaths, excludePaths });

  if (chatBotConfig.enabled && key !== '' && showChatBot) {
    return <ChatBotInit visibility={chatBotConfig.visibility} widgetKey={key} />;
  }

  return null;
};
